import React, { useState, useRef } from "react";
import Header from "../../../component/Head";
import Footer from "../../../component/Footer";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Radio,
  DatePicker,
  Cascader,
  message,
  Result,
  Modal,
} from "antd";
import {
  defaultGutter,
  editFormColSpan,
  formItemLayout,
} from "../../../static/formLayoutConfig";
import { address } from "../../../static/address";
import { bankSelect } from "../../../static/option";
import ImgUploader from "../../../component/ImgUploader";

import "./style.scss";
import formValid from "../../../func/formValid";
import ajax from "../../../func/ajax";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();
  const [otherBank, setOtherBank] = useState(null);
  const [liked, setLiked] = useState(true);
  const [count, setCount] = useState(60);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const bank = useRef("");//input事件保存输入的值
  //倒计时
  const sendCode = () => {
    let counts = count - 1;
    const timer = setInterval(() => {
      setCount(counts--);
      setLiked(false);
      if (counts <= -1) {
        clearInterval(timer);
        setLiked(true);
        setCount(60);
      }
    }, 1000);
  };

  //其他银行Input输入事件
  const handleInputBank = (e) => {
    console.log(e.target.value);
    // setOtherBank(e.target.value);
    bank.current = e.target.value;
    console.log(bank.current, "bank")

  }

  //ajax获取验证码
  const getCode = (phone) => {
    ajax({
      path: `/creditor/iphonesend/${phone}`,
      data: { phone },
      method: "get",
      isNeedToken: false,
    }).then((res) => {
      console.log(res);
    });
  };

  //验证码操作
  const verifiedSubmit = () => {
    let { phone } = form.getFieldsValue();
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (phone && reg.test(phone)) {
      sendCode();
      getCode(phone);
    } else {
      message.error("请输入正确的手机号");
      return;
    }
  };
  const handleSubmit = () => {
    formValid(form).then(() => {
      let {
        name,
        email,
        link_name,
        phone,
        company_address,
        company_address_add,
        phone_code,
        legal_person,
        id_card,
        f_id_card,
        r_id_card,
        credit_code,
        business_license,
        // registered_date,
        intention_region,
      } = form.getFieldsValue();

      if (!company_address_add) {
        company_address_add = "";
      }

      ajax({
        path: "/creditor/v1/service",
        isNeedToken: false,
        data: {
          name,
          link_name,
          company_address: company_address.join("") + company_address_add,
          phone,
          phone_code,
          legal_person,
          id_card,
          credit_code,
          // registered_date: moment(registered_date).format("YYYY-MM-DD"),
          intention_region: intention_region.join(""),
          f_id_card: f_id_card[0],
          r_id_card: r_id_card[0],
          business_license: business_license[0],
        },
      }).then((res) => {
        if (res.error_code === 0) {
          setShowModal(true);
        }
      });
    });
  };

  return (
    <div className={"serviceProviderApply-container"}>
      <Header currentIndex={5} />
      <div className={"serviceProviderApply-content"}>
        <div className={"serviceProviderApply-main"}>
          <div className={"serviceProviderApply-header container-1200"}>
            申请入驻
          </div>
          <div className={"serviceProviderApply-form container-1200"}>
            <Form
              form={form}
              {...formItemLayout}
              initialValues={{
                type: 3,
              }}
            >
              <div className={"serviceProviderApply-form-item"}>
                <h3>填写基本信息</h3>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"品牌(门店)名称"}
                      name="name"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <Input placeholder={"请输入品牌(门店)名称"} allowClear />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"联系人"}
                      name="link_name"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <Input placeholder={"请输入联系人姓名"} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"手机号"}
                      name="phone"
                      rules={[
                        {
                          required: true,
                          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                          message: "请输入正确的手机号码",
                        },
                      ]}
                    >
                      <Input placeholder={"请输入联系人手机号"} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"手机验证码"}
                      name="phone_code"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <Input placeholder={"请输入手机验证码"} allowClear />
                    </Form.Item>
                  </Col>
                  <Col {...editFormColSpan}>
                    <Button
                      disabled={liked ? false : true}
                      onClick={verifiedSubmit}
                      className={liked ? "verificationCode" : ""}
                    >
                      {liked ? "获取验证码" : count + "秒后重发"}
                    </Button>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"地址"}
                      name="company_address"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <Cascader
                        fieldNames={{
                          label: "title",
                          value: "title",
                          children: "children",
                        }}
                        options={address}
                        placeholder="请选择"
                      />
                    </Form.Item>
                  </Col>
                  <Col {...editFormColSpan}>
                    <Form.Item label={""} name="company_address_add">
                      <Input placeholder={"请输入详细地址"} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className={"serviceProviderApply-form-item"}>
                <h3>填写证明材料</h3>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"法人姓名"}
                      name="legal_person"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <Input placeholder={"请输入法人姓名"} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"法人身份证号码"}
                      name="id_card"
                      rules={[{ required: true, message: "法人身份证号码" }]}
                    >
                      <Input placeholder={"请输入法人身份证号码"} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"法人身份证正面照"}
                      name="f_id_card"
                      rules={[{ required: true, message: "" }]}
                    >
                      <ImgUploader maxNumber={1} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"法人身份证反面照"}
                      name="r_id_card"
                      rules={[{ required: true, message: "" }]}
                    >
                      <ImgUploader maxNumber={1} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"统一社会信用代码"}
                      name="credit_code"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <Input
                        placeholder={"请输入统一社会信用代码"}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"企业营业执照照片"}
                      name="business_license"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <ImgUploader maxNumber={1} />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"企业注册时间"}
                      name="registered_date"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <DatePicker
                        format="YYYY-MM-DD"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                <Row gutter={defaultGutter}>
                  <Col {...editFormColSpan}>
                    <Form.Item
                      label={"意向区域"}
                      name="intention_region"
                      rules={[{ required: true, message: "该项为必填项" }]}
                    >
                      <Cascader
                        fieldNames={{
                          label: "title",
                          value: "title",
                          children: "children",
                        }}
                        options={address}
                        placeholder="请选择"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className={"handle_submit"}>
            <Button onClick={handleSubmit} type={"primary"}>
              下一步
            </Button>
          </div>
          <Modal title="" closable={false} visible={showModal} footer={null}>
            <Result
              status="success"
              title="审核已提交"
              // subTitle="您的入驻信息已经提交申请，会在三个工作日内通过邮件答复"
              subTitle="您的入驻信息已经提交申请，客户经理会在三个工作日内联系您！"

              extra={[
                <Button
                  style={{ width: "120px", height: "40px" }}
                  type="primary"
                  key="console"
                  onClick={() => {
                    setShowModal(false);
                    history.push("/serviceProvider");
                  }}
                >
                  确定
                </Button>,
              ]}
            />
          </Modal>
        </div>
      </div>
      <Footer />
    </div>
  );
};
