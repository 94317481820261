import React from "react";
import { Button } from "antd";
import Head from "../../../component/Head";
import Footer from "../../../component/Footer";
import "./style.scss";
import { ForwardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div className={"serviceProviderIndex-container"}>
      <Head currentIndex={5} />
      <div className={"serviceProviderIndex-content"}>
        <div className={"banner"}>
          <div className="banner-btn">
            <Link to={"/serviceProviderApply"}>申请入驻</Link>
          </div>
        </div>
        <div className={"container-1200 Advantage"}>
          <div className={"serviceProviderIndex-mainTitle"}>
            <div className={"bold"}>我们的优势</div>
          </div>
          <div className={"Advantage-container"}>
            <ul className={"serviceProviderIndex-parts"}>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/e60ca798852392cc7652fd92c22f8181.png"
                  alt=""
                />
                <div className={"serviceProviderIndex-parts-des right"}>
                  <div>
                    <ForwardOutlined rotate={"180"} />
                    &nbsp;全国司法拍卖标的物
                  </div>
                  <span className={"des-infos"}>
                    包含七大拍卖平台的标的物数据，一站式查看全国xxx个城市的司法拍卖信息。
                  </span>
                </div>
              </li>
              <li>
                <div className={"serviceProviderIndex-parts-des left"}>
                  <div>
                    海量的司法拍卖客户群体&nbsp;
                    <ForwardOutlined />
                  </div>
                  <span className={"des-infos"}>
                    累积大量的司法拍卖客户群体，可以有效帮助
                  </span>
                </div>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/dc92cac46ae616a0363f9071a715aaab.png"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
        <div className={"support"}>
          <div className={"serviceProviderIndex-mainTitle"}>
            <div className={"bold"}>强有力的支持</div>
          </div>
          <div className={"support-container container-1200"}>
            <ul className={"serviceProviderIndex-parts"}>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/4eb07085f46f7b536b59ed87fea474fd.png"
                  alt=""
                />
                <div className={"serviceProviderIndex-parts-title"}>
                  完善的培训体系
                </div>
                <div className={"serviceProviderIndex-parts-des"}>
                  会对服务商进行技能培训，提升服务商的服务专业度，帮助服务商快速成长。{" "}
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/73e8a8b6b9590b3120c1dbba7b7113f8.png"
                  alt=""
                />
                <div className={"serviceProviderIndex-parts-title"}>
                  强大的技术支持
                </div>
                <div className={"serviceProviderIndex-parts-des"}>
                  专业的技术支持人员随时帮助服务商解决问题、产品、技术相关问题。{" "}
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/6659e19f5b42644b555f2a4b5936ab15.png"
                  alt=""
                />
                <div className={"serviceProviderIndex-parts-title"}>
                  丰富的资源共享
                </div>
                <div className={"serviceProviderIndex-parts-des"}>
                  公司大量文件、图片、宣传资料等进行分类共享，享受公司同步资源。
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/22f420cf06cf92cf046ff3ec1605b69d.png"
                  alt=""
                />
                <div className={"serviceProviderIndex-parts-title"}>
                  稳固的公司渠道
                </div>
                <div className={"serviceProviderIndex-parts-des"}>
                  公司会作为服务商最强有力的支持，提供多种渠道和方式对业务进行最大帮助。
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={"common-problem container-1200"}>
          <div className={"serviceProviderIndex-mainTitle"}>
            <div className={"bold"}>常见问题</div>
          </div>
          <ul className={"common-problem-content"}>
            <li>
              <div>
                <span></span>
                服务商入驻，需要有什么费用嘛？
              </div>
              <div>
                <span></span>
                成为矩阵资产资产营销平台的服务商，需要缴纳加盟费用，具体按照加盟区域而定。
              </div>
            </li>
            <li>
              <div>
                <span></span>
                怎么入驻矩阵资产平台成为服务商？
              </div>
              <div>
                <span></span>
                可以直接通过页面申请入驻提交入驻信息后等待审核，会有工作人员与您联系具
                <br />
                体沟通，并且签订协议，缴纳加盟费用后可成功入驻。
              </div>
            </li>
            <li>
              <div>
                <span></span>
                入驻矩阵资产平台具体有什么优势？
              </div>
              <div>
                <span></span>
                成为矩阵资产资产营销平台的服务商，加盟区域内的七大平台的司法拍卖数据，通过
                <br />
                平台的赋能，为客户提供专业司法拍卖服务。
              </div>
            </li>
          </ul>
        </div>
        <div className={"coopers"}>
          <div className={"serviceProviderIndex-mainTitle"}>
            <div className={"bold"}>合作服务商</div>
          </div>
          <div className={"coopers-container container-1200"}>
            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/43ad86b9f7afdaa4367114fd60458119.png"
                alt=""
              />
              <div>杭州来拍科技有限公司</div>
            </div>
            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/43ad86b9f7afdaa4367114fd60458119.png"
                alt=""
              />
              <div>杭州来拍科技有限公司</div>
            </div>
            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/43ad86b9f7afdaa4367114fd60458119.png"
                alt=""
              />
              <div>杭州来拍科技有限公司</div>
            </div>
            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/43ad86b9f7afdaa4367114fd60458119.png"
                alt=""
              />
              <div>杭州来拍科技有限公司</div>
            </div>
          </div>
          <div className={"serviceProvider-submit container-1200"}>
            <Button type={"primary"}>
              <Link to={"/serviceProviderApply"}>申请入驻</Link>
            </Button>
            <div>入驻之前有疑问，可以联系邮箱，电话。</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
