import React from "react";
import { Button } from "antd";
import Head from "../../../component/Head";
import Footer from "../../../component/Footer";
import "./index.scss";
import { ForwardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div className={"brokerIndex-container"}>
      <Head currentIndex={5} />
      <div className={"brokerIndex-content"}>
        <div className={"banner"}>
          <div className="banner-text">招募全国独立经纪人</div>
          <div className="banner-btn">
            <Link to={"/brokerApply"}>申请入驻</Link>
          </div>
        </div>
        {/* 优势 */}
        <div className={"support"}>
          <div className={"brokerIndex-mainTitle"}>
            <div className={"bold"}>我们的优势</div>
          </div>
          <div className={"support-container container-1200"}>
            <ul className={"brokerIndex-parts"}>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655197863435_%E8%B5%84%E4%BA%A7%E5%9B%BE%E7%89%87@2x.png"
                  alt=""
                />

                <div className={"brokerIndex-parts-des"}>
                  独创的法拍独立经纪人系统，具备个性化营销工具及个人管理中心。
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655198286760_%E8%B5%84%E4%BA%A7%E5%9B%BE%E7%89%87%E5%A4%87%E4%BB%BD@2x.png"
                  alt=""
                />

                <div className={"brokerIndex-parts-des"}>
                  7年及以上的法拍房+互联网精英队。
                </div>
              </li>
              <li>
                <img
                  src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655198320954_%E8%B5%84%E4%BA%A7%E5%9B%BE%E7%89%87%E5%A4%87%E4%BB%BD%202@2x.png"
                  alt=""
                />

                <div className={"brokerIndex-parts-des"}>
                  先进的区块链技术对所有数据传输进行加密存储，采用分布式架构，支撑高并发、高访问情况，成功对接七大网拍处置平台，实时更新标的物拍卖信息。
                </div>
              </li>

            </ul>
          </div>
        </div>

        {/* 为什么 */}
        <div style={{ background: '#FAFBFF' }}>
          <div className={"container-1200 Advantage"}>
            <div className={"brokerIndex-mainTitle"}>
              <div className={"bold"}>为什么要加盟矩阵资产平台独立经纪人系统？</div>
            </div>
            <div className={"support-container container-1200"}>
              <ul className={"brokerIndex-parts"}>
                <li style={{ backgroundImage: "url('https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655200164435_%E4%BD%8D%E5%9B%BE@2x.png')" }}>
                  <div className={"brokerIndex-parts-des"}>
                    <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655199687456_%E7%BC%96%E7%BB%84@2x(1).png" alt="" />
                    <div>市场空间大</div>
                  </div>
                </li>

                <li style={{ backgroundImage: "url('https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655200580689_%E4%BD%8D%E5%9B%BE@2x(1).png')" }}>
                  <div className={"brokerIndex-parts-des"} >
                    <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655200486935_%E7%BC%96%E7%BB%84@2x(2).png" alt="" />
                    <div>运营成本低</div>
                  </div>
                </li>

                <li style={{ backgroundImage: "url('https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655200971814_%E4%BD%8D%E5%9B%BE@2x(2).png')" }}>
                  <div className={"brokerIndex-parts-des"} >
                    <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655200509566_%E7%BC%96%E7%BB%84@2x(3).png" alt="" />
                    <div>共同起步，并肩发展</div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>


        {/* 加盟条件 */}

        <div className={"condition container-1200"}>
          <div className={"brokerIndex-mainTitle"}>
            <div className={"bold"}>有什么样的加盟条件？</div>
          </div>
          <div className="condition-container">
            <div className="condition-container_left">
              <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655201312377_img%EF%BC%8F%E7%94%A8%E6%88%B7%E7%97%9B%E7%82%B9@2x.png" alt="" />
            </div>

            <div className="condition-container_right">
              <ul>
                <li>
                  <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655201650459_icon%EF%BC%8F%E7%83%AD%E9%97%A8%E6%90%9C%E7%B4%A2%E5%A4%87%E4%BB%BD%203@2x.png" alt="" />
                  <span>以加盟合作的方式在当地开展“矩阵法拍房独立经纪人联盟”，统一理念，统一宣传、统一品牌、统一服务。</span>
                </li>
                <li>
                  <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202008289_icon%EF%BC%8F%E7%83%AD%E9%97%A8%E6%90%9C%E7%B4%A2%E5%A4%87%E4%BB%BD%202@2x(1).png" alt="" />
                  <span>目标城市：全国一二三线城市，市场经济较为发达的地级市。</span>
                </li>
                <li>
                  <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202027856_icon%EF%BC%8F%E7%83%AD%E9%97%A8%E6%90%9C%E7%B4%A2%E5%A4%87%E4%BB%BD%206@2x.png" alt="" />
                  <span>资源条件：有具备三年以上的房地产市场从业经验，具备一定的互联网意识。</span>
                </li>
                <li>
                  <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202051944_icon%EF%BC%8F%E7%83%AD%E9%97%A8%E6%90%9C%E7%B4%A2%E5%A4%87%E4%BB%BD%205@2x.png" alt="" />
                  <span>观念要求：认同法拍房的发展前景，具备服务意识和主观能动性。</span>
                </li>
              </ul>

              <div className="condition-container_right-bottom">
                <img src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202120119_%E7%BC%96%E7%BB%84%206@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* 加盟流程 */}
        <div className={"coopers"}>
          <div className={"brokerIndex-mainTitle"}>
            <div className={"bold"}>加盟流程</div>
          </div>
          <div className={"coopers-container container-1200"}>
            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202321012_%E7%BC%96%E7%BB%84@2x.png"
                alt=""
              />

            </div>
            <img className="icon" src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202555471_%E7%BC%96%E7%BB%84@2x(4).png" alt="" />
            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202419568_%E7%BC%96%E7%BB%84%202@2x.png"
                alt=""
              />

            </div>
            <img className="icon" src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202555471_%E7%BC%96%E7%BB%84@2x(4).png" alt="" />

            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202454827_%E7%BC%96%E7%BB%84%203@2x.png"
                alt=""
              />

            </div>
            <img className="icon" src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202555471_%E7%BC%96%E7%BB%84@2x(4).png" alt="" />

            <div className={"coopers-item"}>
              <img
                src="https://laipai-img.oss-cn-hangzhou.aliyuncs.com/webStorage/1655202496752_%E7%BC%96%E7%BB%84%204@2x.png"
              />

            </div>
          </div>
          <div className={"brokerIndex-submit container-1200"}>
            <Button type={"primary"}>
              <Link to={"/brokerApply"}>申请入驻</Link>
            </Button>
            <div>入驻之前有疑问，可以联系邮箱，电话。</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
